//GeneratorFlag:[NoChange]
import ext from './fa-ext.js'
export default {
	"name":"ai",
	"ext": ext,
	"layout":{
//GeneratorFlag:[NewLayout]
	},
	'bot':'ربات',
	'botAttachment':'پیوست فایل',
	'botBotId':'شناسه',
	'botBotName':'نام',
	'botBotStatus':'وضعیت',
	'botBotTrack':'اطلاعات رهگیری ثبت',
	'botCaption':'توضیح کوتاه',
	'botCreate':'ثبت ربات جدید',
	'botCreateImport':'ثبت ربات ها از طریق اکسل',
	'botDelete':'حذف ربات',
	'botDetail':'جزئیات اطلاعات ربات',
	'botIdentifier':'کلید یکتا',
	'botImageAttachment':'پیوست تصویر',
	'botIsActive':'آیا فعال است؟',
	'botJsonConfig':'کانفیگ',
	'botLayout':'ربات ها',
	'botList':'فهرست ربات ها',
	'botUpdate':'ویرایش ربات',
	'calculateTypeCalculateTypeId':'شناسه',
	'calculateTypeCaption':'توضیحات',
	'calculateTypeTitle':'عنوان',
	'discount':'تخفیف',
	'discountAttachment':'پیوست فایل',
	'discountCouponCode':'کد تخفیف',
	'discountCreate':'ثبت تخفیف جدید',
	'discountCreateImport':'ثبت تخفیف ها از طریق اکسل',
	'discountDelete':'حذف تخفیف',
	'discountDetail':'جزئیات اطلاعات تخفیف',
	'discountDiscountAmount':'مبلغ تخفیف',
	'discountDiscountId':'شناسه',
	'discountDiscountPercentage':'درصد تحفیف',
	'discountDiscountTrack':'اطلاعات رهگیری ثبت',
	'discountEndUtcDate':'تاریخ پایان',
	'discountIdentifier':'کلید یکتا',
	'discountLayout':'تخفیف ها',
	'discountList':'فهرست تخفیف ها',
	'discountStartUtcDate':'تاریخ شروع',
	'discountTitle':'عنوان',
	'discountUpdate':'ویرایش تخفیف',
	'discountUsageLimit':'تعداد مجاز استفاده',
	'discountWaitingHours':'مدت انتظار تکرار به ساعت',
	'package':'پکیج',
	'packageAttachment':'پیوست فایل',
	'packageCalculateType':'روش محاسبه',
	'packageCalculateTypeId':'روش محاسبه',
	'packageCaption':'توضیحات',
	'packageCreate':'ثبت پکیج جدید',
	'packageCreateImport':'ثبت پکیج ها از طریق اکسل',
	'packageDayCount':'تعداد روز',
	'packageDelete':'حذف پکیج',
	'packageDetail':'جزئیات اطلاعات پکیج',
	'packageDiscount':'تخفیف',
	'packageDiscountId':'تخفیف',
	'packageDocumentCount':'تعداد پردازش سند',
	'packageFileCount':'تعداد پردازش فایل',
	'packageIdentifier':'کلید یکتا',
	'packageImageCount':'تعداد ایجاد تصویر',
	'packageIsActive':'آیا فعال است؟',
	'packageIsDefault':'سرویس پیشفرض؟',
	'packageLanguagesModels':'مدل های زبانی',
	'packageLayout':'پکیج ها',
	'packageLimitationUsage':'تعداد مجاز',
	'packageList':'فهرست پکیج ها',
	'packagePackageId':'شناسه',
	'packagePackageTrack':'اطلاعات رهگیری ثبت',
	'packagePrice':'قیمت',
	'packageProductKey':'کلید محصول',
	'packageTitle':'عنوان',
	'packageUpdate':'ویرایش پکیج',
	'packageUseHistory':'حافظه مکالمه؟',
	'prompt':' پرامپت',
	'promptAttachment':'پیوست فایل',
	'promptCreate':'ثبت  پرامپت جدید',
	'promptCreateImport':'ثبت  پرامپت ها از طریق اکسل',
	'promptDelete':'حذف  پرامپت',
	'promptDetail':'جزئیات اطلاعات  پرامپت',
	'promptGroup':'گروه پرامپت',
	'promptGroupAttachment':'پیوست فایل',
	'promptGroupCaption':'توضیحات',
	'promptGroupCreate':'ثبت گروه پرامپت جدید',
	'promptGroupCreateImport':'ثبت گروه های پرامپت از طریق اکسل',
	'promptGroupDelete':'حذف گروه پرامپت',
	'promptGroupDetail':'جزئیات اطلاعات گروه پرامپت',
	'promptGroupHexColor':'رنگ',
	'promptGroupIdentifier':'کلید یکتا',
	'promptGroupImageAttachment':'پیوست تصویر',
	'promptGroupLayout':'گروه های پرامپت',
	'promptGroupList':'فهرست گروه های پرامپت',
	'promptGroupPromptGroupId':'شناسه',
	'promptGroupPromptGroupTrack':'اطلاعات رهگیری ثبت',
	'promptGroupTitle':'عنوان',
	'promptGroupUpdate':'ویرایش گروه پرامپت',
	'promptIdentifier':'کلید یکتا',
	'promptImageAttachment':'پیوست تصویر',
	'promptIsActive':'آیا فعال است؟',
	'promptLayout':' پرامپت ها',
	'promptList':'فهرست  پرامپت ها',
	'promptPromptGroup':'گروه',
	'promptPromptGroupId':'گروه',
	'promptPromptId':'شناسه',
	'promptPromptTrack':'اطلاعات رهگیری ثبت',
	'promptStringContent':'محتوا',
	'promptUpdate':'ویرایش  پرامپت',

}