import { createApp } from 'vue'

import './assets/fonts/iransans-x/fontiran.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import "toastify-js/src/toastify.css"
import './assets/css/toastr-custom.css'

import './assets/css/app.css'
import './assets/css/themes/default.css'
import './assets/css/popper-theme.css'
import 'swiper/css'
import './registerServiceWorker'

import App from './App.vue'
import launcher from './launcher'
import KavehForm from './components/form/form'
import KavehTable from './components/table/table'
import KavehList from './components/list/list'
import KavehPopup from './components/popup/popup'
import KavehCollapse from './components/collapse/collapse'
import KavehButton from './components/button/button'
import KavehExcelImport from './components/excel/excel-import'
import KavehIconBootstrap from './components/icon/icon-bootstrap'
import KavehTaskManager from './components/task/task-manager'
import KavehContent from './components/content/content'
import KavehOrd from './components/ord/ord-container'
import KavehOrdNode from './components/ord/ord-node'
import KavehLoading from './components/common/common-loading'
import KavehConfirmDelete from './components/common/common-confirm-delete'
import KavehOverlay from './components/overlay/overlay'
import KavehTree from './components/tree/tree-base'
import KavehTreeNode from './components/tree/tree-node'
import KavehTextEditor from './components/text-editor/text-editor'
import KavehUploader from './components/uploader/uploader-base'
import KavehCamera from './components/camera/camera'
import KavehCodeEditor from './components/code-editor/code-editor'
import KavehSignaturePad from './components/signature-pad/signature-pad-base'

import popper from 'vue3-popper'
import DatePicker from 'vue3-persian-datetime-picker'
import VueBarcode from '@chenfengyuan/vue-barcode';


const app = createApp(App)
app.component('kaveh-button', KavehButton)
app.component('kaveh-table', KavehTable)
app.component('kaveh-list', KavehList)
app.component('kaveh-form', KavehForm)
app.component('kaveh-popup', KavehPopup)
app.component('kaveh-excel-import', KavehExcelImport)
app.component('kaveh-task-manager', KavehTaskManager)
app.component('kaveh-icon-bootstrap', KavehIconBootstrap)
app.component('kaveh-content', KavehContent)
app.component('kaveh-collapse', KavehCollapse)
app.component('kaveh-ord', KavehOrd)
app.component('kaveh-ord-node', KavehOrdNode)
app.component('kaveh-loading', KavehLoading)
app.component('kaveh-confirm-delete', KavehConfirmDelete)
app.component('kaveh-overlay', KavehOverlay)
app.component('kaveh-tree', KavehTree)
app.component('kaveh-tree-node', KavehTreeNode)
app.component('kaveh-text-editor', KavehTextEditor)
app.component('kaveh-uploader', KavehUploader)
app.component('date-picker', DatePicker)
app.component('kaveh-camera', KavehCamera)
app.component('popper', popper)
app.component('vue-barcode', VueBarcode)
app.component('kaveh-code-editor', KavehCodeEditor)
app.component('kaveh-signature-pad', KavehSignaturePad)

launcher.launch(app)

