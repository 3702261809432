import mitt from 'mitt'
import store from '@/core/store'
import Router from '@/core/router'
import Localization from '@/core/localization'
import plugins from '@/plugins'
import resources from '@/core/resources'
import NodeRSA from 'node-rsa';

const importModules = async function (modules)
{
	const result = [];

	for (var i = 0; i < modules.length; i++)
	{
		try
		{
			var module =
				require(`/src/modules/${modules[i]}`);

			if (module)
			{
				result.push(module.default);
			}

		} catch (e) { }
	}

	return result;
}

const getResource = function (modules)
{
	for (var i = 0; i < modules.length; i++)
	{
		const item = modules[i];
		if (item && item.name && item.resources)
		{
			const keys = Object.keys(item.resources);
			for (var ii = 0; ii < keys.length; ii++)
			{
				const lang = keys[ii]
				resources[lang][item.name] = item.resources[lang]
			}
		}
	}

	return resources;
}

const getOverrideResource = async function ()
{
	const url = `${process.env.BASE_URL}config/override-language-resources.json?v=${Date.now()}`
	const response = await fetch(url);
	const ols = await response.json();

	return ols
}

const getToolbar = function (modules)
{
	var toolbarItems = []

	for (var i = 0; i < modules.length; i++)
	{
		const item = modules[i];
		if (item.toolbar)
		{
			toolbarItems = [...toolbarItems, ...item.toolbar.items]
		}
	}

	return {
		items: toolbarItems
	};
}


const setRoutesAccess = (routes) =>
{

	for (var i = 0; i < routes.length; i++)
	{

		const route = routes[i];
		const clientId = store.state.client.id;
		const accessKeys = store.state.client.accessKeys;
		var clientAccessKey = ''

		if (route.meta.clientAccessKeys && route.meta.clientAccessKeys.length > 0)
		{
			clientAccessKey = route.meta.clientAccessKeys[0]
		}

		route.meta.clientHasAccess = clientId == 1 || accessKeys.findIndex((x) =>
		{
			return x.keyName == clientAccessKey && x.groupName == 'client-web'
		}) > -1

		if (route.children)
		{
			setRoutesAccess(route.children)
		}
	}
}

const getServers = async () =>
{
	const url = `${process.env.BASE_URL}config/server.json?v=${Date.now()}`
	const response = await fetch(url);
	const config = await response.json();

	return config[process.env.NODE_ENV].servers
}


const assetPrivateLink = (key, isInline, addToken) =>
{
	const servers = store.state.server.servers;
	var serverConfig = servers.find(x => x.name == 'asset')
	if (!serverConfig)
	{
		serverConfig = servers.find(x => x.name == 'public')
	}

	const action = isInline ? 'show' : 'download';

	if (addToken)
	{
		const token = store.state.client.token;
		return `${serverConfig.host}asset/file/${action}/${key}?access_token=${token}`
	}

	return `${serverConfig.host}asset/file/${action}/${key}`
}

const assetPublicLink = (path) =>
{
	const servers = store.state.server.servers;
	var serverConfig = servers.find(x => x.name == 'asset')
	if (!serverConfig)
	{
		serverConfig = servers.find(x => x.name == 'public')
	}

	path = path.split('\\').join('/')
	return `${serverConfig.host}${path}`;

}

const assetDownload = (key, fileName) => new Promise((resolve, reject) =>
{
	const url =
		assetPrivateLink(key, false, true)

	fetch(url).then(res => res.blob()).then(blob =>
	{
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = fileName;
		link.click();

		resolve(true)
	}).catch((e) => reject(e));
})


const getRsaKeys = async () =>
{
	const rsa = new NodeRSA({ b: 2048 });

	rsa.setOptions({
		encryptionScheme: 'pkcs1'
	})

	const privateKey = rsa.exportKey('private');
	const publicKey = rsa.exportKey('public');

	return {
		publicKey,
		privateKey
	}
}


const getAppConfig = async () =>
{
	const url = `${process.env.BASE_URL}config/config.json?v=${Date.now()}`
	const response = await fetch(url);
	const config = await response.json();

	return config;
}

const launch = async function (app)
{
	const moduleNames = JSON.parse(process.env.VUE_APP_MODULES);
	const language = localStorage.getItem("kaveh.app.language") || process.env.VUE_APP_LANGUEGE;
	const direction = localStorage.getItem("kaveh.app.direction") || process.env.VUE_APP_DIRECTION;
	const color = localStorage.getItem("kaveh.app.color") || process.env.VUE_APP_COLOR;
	const layout = localStorage.getItem("kaveh.app.layout") || process.env.VUE_APP_LAYOUT;
	const pushNotificationKey = localStorage.getItem("kaveh.app.pnk") || null;

	const modules = await importModules(moduleNames);
	const toolbar = getToolbar(modules);
	const resource = getResource(modules);
	const overrideResource = await getOverrideResource();
	const servers = await getServers();
	const appConfig = await getAppConfig();
	const rsaKeys = await getRsaKeys();

	await store.dispatch('app/setLanguage', language);
	await store.dispatch('app/setLayout', layout);
	await store.dispatch('app/setColor', color);
	await store.dispatch('app/setDirection', direction);
	await store.dispatch('app/setModules', moduleNames);
	await store.dispatch('app/setResource', resource);
	await store.dispatch('app/setOverrideResource', overrideResource);
	await store.dispatch('app/setConfig', appConfig);
	await store.dispatch('app/setPushNotificationKey', pushNotificationKey);
	await store.dispatch('client/setTokenFromStorage');
	await store.dispatch('client/setAccessKeysFromStorage');
	await store.dispatch('client/setBranchFromStorage');
	await store.dispatch('server/setServers', servers);
	await store.dispatch('server/setRsaPublicKey', rsaKeys.publicKey);
	await store.dispatch('server/setRsaPrivateKey', rsaKeys.privateKey);

	app.config.globalProperties.$emitter = mitt();
	app.config.globalProperties.$toolbar = toolbar;
	app.config.globalProperties.$plugins = plugins;
	app.config.globalProperties.$assetPrivateLink = assetPrivateLink;
	app.config.globalProperties.$assetPublicLink = assetPublicLink;
	app.config.globalProperties.$assetDownload = assetDownload;
	app.config.globalProperties.$loc = new Localization();

	const router = new Router(modules).create();
	setRoutesAccess(router.options.routes[0].children);

	if (store.state.client.token)
	{
		window.$router = router;
	}

	window.$keys = {};

	app.use(store);
	app.use(router);
	app.mount('#app');
}

export default { launch }
