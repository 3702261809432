import qs from 'qs';
import axios from 'axios'
import { toast, string } from '@/plugins'
import store from '@/core/store'
import ServerUtility from './server-utility'
import Ws from './ws'
import NodeRSA from 'node-rsa';
import CryptoJS from 'crypto-js';

export default class Server
{
	constructor(config)
	{
		this.gateway = 'public';
		this.config = config || {};

		if (!config.useHttp && store.state.server.socket != null)
		{
			this.initSocket()
		}
		else
		{
			this.initAxios()
		}
	}

	initSocket()
	{
		const invoke = (path, payload) => new Promise((resolve, reject) =>
		{
			const ws = new Ws();
			const utility = new ServerUtility();

			ws.getConnection(path).then((connection) =>
			{
				const success = (data) =>
				{
					if (this.config.HSR)
					{
						this.handelSuccessResponse({
							status: 200, data: data
						});
					}

					resolve({
						status: 200, data: data
					});
				}

				const error = (data) =>
				{
					if (this.config.HER)
					{
						this.handleErrorResponse(data);
					}

					reject(data)
				}

				const methodName =
					utility.pathToMethod(path);

				if (payload)
				{
					connection.invoke(methodName, payload).
						then(success).catch(error);
				}
				else
				{
					connection.invoke(methodName).
						then(success).catch(error);
				}

			}).catch((e) =>
			{
				if (this.config.HER)
				{
					this.handleErrorResponse(e);
				}

				reject(e)
			})
		})


		this.get = (path, payload) =>
		{
			if (payload.params)
			{
				return invoke(path, payload.params)
			}

			return invoke(path)
		};

		this.post = (path, payload) =>
		{
			return invoke(path, payload)
		};

		this.delete = (path, payload) =>
		{
			if (payload.params)
			{
				if (payload.params.id)
				{
					return invoke(path, payload.params.id)
				}
				else
				{
					return invoke(path, payload.params)
				}
			}

			return invoke(path)
		};;
	}

	initAxios()
	{
		const request = (path) =>
		{
			const utility = new ServerUtility();
			const serverConfig = utility.getServerConfig(path);

			const server = axios.create({
				baseURL: serverConfig.host
			});

			server.interceptors.request.use(async (config) =>
			{
				if (this.config.headers)
				{
					config.headers = {
						...config.headers,
						...this.config.headers
					};
				}

				if (store.state.client.branchId)
				{
					config.headers['branch-id'] = store.state.client.branchId;
				}

				if (serverConfig.useE2EE)
				{
					if (store.state.server.aesKey == null)
					{
						await this.setAesKey(serverConfig.host);
					}

					config.headers['e2ee'] = '1'
				}

				config.headers['Authorization'] =
					`Bearer ${store.state.client.token}`;

				config.paramsSerializer = {
					serialize: (params) =>
					{
						return qs.stringify(params, {
							arrayFormat: 'repeat',
							allowDots: true
						});
					}
				};

				if (config.data && serverConfig.useE2EE)
				{
					config.data =
						this.aesEncrypt(config.data,
							store.state.server.aesKey)
				}

				return config;
			}, (error) =>
			{
				return Promise.reject(error);
			});

			server.interceptors.response.use(async (response) =>
			{
				if (response.data && serverConfig.useE2EE)
				{
					console.log(response)

					response.data =
						this.aesDecrypt(response.data.data,
							store.state.server.aesKey, response.data.iv)
				}

				if (this.config.HSR)
				{
					this.handelSuccessResponse();
				}

				return response;
			}, async (error) =>
			{
				if (this.config.HER)
				{
					this.handleErrorResponse(error);
				}

				return Promise.reject(error);
			});

			return server;
		};

		this.get = (path, data) =>
		{
			return request(path).get(path, data);
		};

		this.post = (path, data) =>
		{
			return request(path).post(path, data);
		};

		this.delete = (path, data) =>
		{
			return request(path).delete(path, data);
		};
	}


	aesEncrypt(data, secretKey)
	{
		const str = JSON.stringify(data);
		const iv = CryptoJS.lib.WordArray.random(16);
		const encrypted = CryptoJS.AES.encrypt(str, CryptoJS.enc.Hex.parse(secretKey), {
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
			iv: iv
		});


		const ivString = iv.toString(CryptoJS.enc.Hex);
		const encryptedString = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

		return {
			iv: ivString,
			data: encryptedString
		}
	}


	aesDecrypt(encryptedData, secretKey, ivString)
	{
		const decrypted = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Hex.parse(secretKey), {
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
			iv: CryptoJS.enc.Base64.parse(ivString),
		});

		return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
	}


	decryptData(encryptedData)
	{
		const privateKey =
			store.state.server.rsaPrivateKey;

		try
		{
			const rsa = new NodeRSA(privateKey);

			rsa.setOptions({
				encryptionScheme: 'pkcs1'
			})

			return rsa.decrypt(encryptedData, 'utf8');

		} catch (e)
		{
			console.log(e)
		}
	}

	clearRsaKey(originalKey)
	{
		var cleanKey = originalKey
			.replace(/\r/g, '')
			.replace(/\n/g, '')
			.replace('-----BEGIN RSA PRIVATE KEY-----', '')
			.replace('-----END RSA PRIVATE KEY-----', '')
			.replace('-----BEGIN PUBLIC KEY-----', '')
			.replace('-----END PUBLIC KEY-----', '');

		return cleanKey
	}

	async setAesKey(baseUrl)
	{
		const publicKey =
			this.clearRsaKey(store.state.server.rsaPublicKey);


		const url = `${baseUrl}general/security/aes-key`
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ rsaPublicKey: publicKey })
		});

		if (!response.ok)
		{
			this.handleErrorResponse(response.error);
		}

		const encryptedAesKey =
			await response.text();

		await store.dispatch('server/setAesKey', this.decryptData(encryptedAesKey));
	}

	handelSuccessResponse()
	{
		toast.success('status.ok');
	}

	handleErrorResponse(error)
	{
		new ServerUtility().showErrorMessage(error)
	};
}
